import { BikeIcon } from "lucide-react";
import React from "react";
import { Button } from "./ui/button";

import image1 from "../images/1.jpg";
import imageTwo from "../images/2.jpg";
import imageThree from "../images/4.jpg";
import imageOne from "../images/create.png";

function Header() {
  return (
    <nav className="mx-4 pt-4">
      <div className="flex justify-between items-center">
        <div className="text-2xl font-bold items-center flex">
          <BikeIcon size={24} className="inline-block mr-2" />
          bikeride.club
        </div>
        <Button
          variant="default"
          onClick={() => {
            window.location.href = "/session/new";
          }}
        >
          <BikeIcon size={16} className="inline-block" />
          Join a Ride
        </Button>
      </div>
    </nav>
  );
}

function HeroSection() {
  return (
    <div className="mt-6">
      <section className="hero h-[600px] flex items-center justify-center bg-hero-pattern text-center">
        <div className="text-white rounded-xl p-6 ">
          <h1 className="text-5xl font-bold">Ride Together, Explore Beyond</h1>
          <p className="text-lg mt-4">
            Find bike rides near you, connect with other cyclists, and explore
            new routes together.
          </p>
          <Button variant="secondary" size="lg" className="mt-6">
            Create a Ride
          </Button>
        </div>
      </section>
    </div>
  );
}

function FeaturesSection() {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-10 gap-10 mx-8">
      <div className="rounded-2xl p-8 bg-gray-50 transition-all ring-1 ring-gray-200/50 shadow hover:shadow-lg">
        <div className="overflow-hidden flex items-start justify-center h-auto relative lg:h-60">
          <div className="absolute inset-0 bg-gradient-to-t from-gray-50 via-transparent z-10"></div>
          <picture>
            <img
              src={imageTwo}
              alt="Feature image"
              className="h-full w-full object-contain"
              loading="lazy"
              width="1280"
              height="800"
              decoding="async"
            />
          </picture>
        </div>
        <div className="mt-2">
          <span className="bg-indigo-100 border-indigo-200 border text-indigo-600 rounded-full text-xs font-medium px-3 py-1 ">
            <span>Search</span>{" "}
          </span>
          <h3 className="text-xl lg:text-2xl font-medium mt-2">
            <span>Find Rides Near You</span>
          </h3>
          <p className="text-slate-600 mt-2 [text-wrap:balance]">
            <span>
              Connect with nearby cyclists and find group rides tailored to your
              interests and skill level.
            </span>
          </p>
        </div>
      </div>
      <div className="rounded-2xl p-8 bg-gray-50 transition-all ring-1 ring-gray-200/50 shadow hover:shadow-lg">
        <div className="overflow-hidden flex items-start justify-center h-auto relative lg:h-60">
          <div className="absolute inset-0 bg-gradient-to-t from-gray-50 via-transparent z-10"></div>
          <picture>
            <img
              src={imageThree}
              alt="Feature image"
              className="h-full w-full object-contain"
              loading="lazy"
              width="1280"
              height="800"
              decoding="async"
            />
          </picture>
        </div>
        <div className="mt-2">
          <span className="bg-purple-100 border-purple-200 border text-purple-600 rounded-full text-xs font-medium px-3 py-1 ">
            {" "}
            <span>Manage</span>{" "}
          </span>
          <h3 className="text-xl lg:text-2xl font-medium mt-2">
            <span>Create Your Own Ride</span>
          </h3>
          <p className="text-slate-600 mt-2 [text-wrap:balance]">
            <span>
              Organize a ride, set the route, invite friends, and enjoy a custom
              cycling experience!{" "}
            </span>
          </p>
        </div>
      </div>
      <div className="rounded-2xl p-8 bg-gray-50 transition-all ring-1 ring-gray-200/50 shadow hover:shadow-lg">
        <div className="overflow-hidden flex items-start justify-center h-auto relative lg:h-60">
          <div className="absolute inset-0 bg-gradient-to-t from-gray-50 via-transparent z-10"></div>
          <img
            src={image1}
            alt="Feature image"
            className="h-full w-full object-cover"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div className="mt-2">
          <span className="bg-gray-100 border-gray-200 border text-gray-800 rounded-full text-xs font-medium px-3 py-1 ">
            <span>Connect</span>{" "}
          </span>
          <h3 className="text-xl lg:text-2xl font-medium mt-2">
            <span> Build Your Community</span>
          </h3>
          <p className="text-slate-600 mt-2 [text-wrap:balance]">
            <span>
              Meet new people, grow your cycling network, and stay connected
              with others who share your passion.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <footer className="py-8 text-black text-center">
      <p className="mt-4">© 2024 BikeRide.Club All Rights Reserved.</p>
    </footer>
  );
}

const Landing: React.FC = () => {
  return (
    <div className="">
      <Header />
      <HeroSection />
      <div className="grid md:grid-cols-2 py-10">
        <div className="grid place-items-center p-8 m-4">
          <img
            src={imageOne}
            alt="Create a Ride"
            className="object-contain w-full h-full"
          />
        </div>
        <div className="grid place-items-center">
          <div className="max-w-xl">
            <span className="bg-purple-100 border-purple-200 border text-purple-600 -ml-px rounded-full text-xs font-medium px-3 py-1 ">
              Simplified Workflows{" "}
            </span>
            <h3 className="text-2xl font-medium mt-4 [text-wrap:balance]">
              Use our intuitive platform to organize your rides and connect with
              other cyclists in your area.
            </h3>
            <p className="mt-4 text-slate-600 [text-wrap:balance]">
              BikeRide.Club is the perfect tool for cyclists who want to
              discover new routes, meet new people, and enjoy the thrill of
              cycling in a group.
            </p>
            <ul className="grid mt-6 text-left gap-y-4">
              <li className="flex items-center gap-3 text-neutral-800">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                  className="w-5 h-5"
                  data-icon="ph:check-circle-fill"
                >
                  <symbol id="ai:ph:check-circle-fill">
                    <path
                      fill="currentColor"
                      d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m45.66 85.66l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 11.32"
                    ></path>
                  </symbol>
                  <use xlinkHref="#ai:ph:check-circle-fill"></use>
                </svg>
                <span className="text-sm">
                  User-Friendly Interface &amp; Design
                </span>
              </li>
              <li className="flex items-center gap-3 text-neutral-800">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                  className="w-5 h-5"
                  data-icon="ph:check-circle-fill"
                >
                  <use xlinkHref="#ai:ph:check-circle-fill"></use>
                </svg>
                <span className="text-sm">
                  Seamless Ride Planning &amp; Management
                </span>
              </li>
              <li className="flex items-center gap-3 text-neutral-800">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                  className="w-5 h-5"
                  data-icon="ph:check-circle-fill"
                >
                  <use xlinkHref="#ai:ph:check-circle-fill"></use>
                </svg>
                <span className="text-sm">
                  Real-Time Notifications &amp; Updates
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FeaturesSection />
      <Footer />
    </div>
  );
};

export default Landing;
