// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import Landing from "@app/components/landing";
import PlacesSearch from "@app/components/places_search";
import RidersNearYou from "@app/components/riders_near_you";
import React from "react";
import ReactDOM from "react-dom/client";

const client = new ApolloClient({
  link: createHttpLink({
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
    },
    uri: "/graphql",
  }),
  cache: new InMemoryCache({}),
});

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("root");
  if (container) {
    const root = ReactDOM.createRoot(container);

    root.render(
      <ApolloProvider client={client}>
        <RidersNearYou />
      </ApolloProvider>,
    );
  }

  const landing = document.getElementById("landing");
  if (landing) {
    const root = ReactDOM.createRoot(landing);
    root.render(<Landing />);
  }

  const placesSearch = document.getElementById("placesSearch");
  if (placesSearch) {
    const root = ReactDOM.createRoot(placesSearch);
    root.render(<PlacesSearch />);
  }
});

// import Feature from 'ol/Feature';
// import Map from 'ol/Map.js';
// import View from 'ol/View.js';
// import { Attribution, defaults as defaultControls } from 'ol/control.js';
// import LineString from 'ol/geom/LineString';
// import Point from 'ol/geom/Point';
// import { Vector as VectorLayer } from 'ol/layer';
// import TileLayer from 'ol/layer/Tile.js';
// import { fromLonLat } from 'ol/proj';
// import { Vector as VectorSource } from 'ol/source';
// import OSM from 'ol/source/OSM.js';
// import { Icon, Stroke, Style } from 'ol/style';

// const view = new View({
//   center: fromLonLat([24.5453, 42.1716]), // Center between Pazardjik and Plovdiv
//   zoom: 9,
// });
// const attribution = new Attribution({
//   collapsible: false,
// });

// const map = new Map({
//   layers: [
//     new TileLayer({
//       source: new OSM(),
//     }),
//   ],
//   controls: defaultControls({attribution: false}).extend([attribution]),
//   target: 'map',
//   view: view,
// });

// const markerSource = new VectorSource();
// const markerLayer = new VectorLayer({
//   source: markerSource,
// });

// // Function to center the map and add a pin on the user's location
// function centerMapOnUserLocation() {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const userLonLat = [position.coords.longitude, position.coords.latitude];
//         const userCenter = fromLonLat(userLonLat);

//         // Set the map's view center to the user's location
//         view.setCenter(userCenter);
//         view.setZoom(12); // Adjust zoom as needed

//         // Create a marker feature
//         const marker = new Feature({
//           geometry: new Point(userCenter),
//         });

//         // Apply a style to the marker (using an icon or simple circle)
//         marker.setStyle(new Style({
//           image: new Icon({
//             src: 'https://www.svgrepo.com/show/31706/map-pin.svg', // Example marker icon URL
//             scale: 0.04, // Scale the icon size if needed
//           })
//         }));

//         // Add the marker to the vector source
//         markerSource.clear(); // Clear any previous markers
//         markerSource.addFeature(marker);
//       },
//       (error) => {
//         console.error("Error getting location:", error);
//         // Handle error or fallback to a default location
//       }
//     );
//   } else {
//     console.error("Geolocation is not supported by this browser.");
//     // Handle the case where geolocation is not available
//   }
// }

// // Add an event listener to a button to request location
// document.getElementById('locateButton').addEventListener('click', () => {
//   centerMapOnUserLocation();
// });

// function searchLocation(location) {
//   const nominatimURL = `https://nominatim.openstreetmap.org/search?q=${location}&format=json&limit=1`;

//   fetch(nominatimURL)
//     .then((response) => response.json())
//     .then((data) => {
//       if (data.length > 0) {
//         const lon = data[0].lon;
//         const lat = data[0].lat;
//         const locationCenter = fromLonLat([parseFloat(lon), parseFloat(lat)]);

//         // Set the map's view center to the searched location
//         map.getView().setCenter(locationCenter);
//         map.getView().setZoom(12); // Adjust zoom as needed

//         // Create a marker feature at the searched location
//         const marker = new Feature({
//           geometry: new Point(locationCenter),
//         });

//         // Apply a style to the marker (using an icon or simple circle)
//         marker.setStyle(
//           new Style({
//             image: new Icon({
//               src: 'https://openlayers.org/en/v6.5.0/examples/data/icon.png', // Example marker icon URL
//               scale: 0.1, // Scale the icon size if needed
//             }),
//           })
//         );

//         // Add the marker to the vector source (clear previous markers)
//         markerSource.clear();
//         markerSource.addFeature(marker);
//       } else {
//         alert("Location not found!");
//       }
//     })
//     .catch((error) => {
//       console.error("Error fetching location data:", error);
//     });
// }

// document.getElementById('searchButton').addEventListener('click', () => {
//   const location = document.getElementById('locationInput').value;
//   if (location) {
//     searchLocation(location);
//   } else {
//     alert("Please enter a location!");
//   }
// });

// map.addLayer(markerLayer);

// // Add the route layer to the map
// const routeSource = new VectorSource();
// const routeLayer = new VectorLayer({
//   source: routeSource,
// });

// // Add the route layer to the map
// map.addLayer(routeLayer);

// // Coordinates for Pazardjik and Plovdiv
// const pazardjikCoords = [24.333, 42.1926]; // Pazardjik
// const plovdivCoords = [24.7453, 42.1506]; // Plovdiv
// const stamboliyskiCoords = [24.5247, 42.1333]; // Stamboliyski

// // Function to draw the shortest route using OSRM
// function drawShortestRoute() {
//   // OSRM API request for the shortest path
//   const osrmUrl = `https://router.project-osrm.org/route/v1/bike/${pazardjikCoords[0]},${pazardjikCoords[1]};${stamboliyskiCoords[0]},${stamboliyskiCoords[1]};${plovdivCoords[0]},${plovdivCoords  [1]}?overview=full&geometries=geojson&annotations=distance`;

//   fetch(osrmUrl)
//     .then(response => response.json())
//     .then(data => {
//       if (data.routes && data.routes.length > 0) {
//         // Extract the route geometry from the response
//         const routeCoords = data.routes[0].geometry.coordinates.map(coord => fromLonLat([coord[0], coord[1]]));

//         // Create a LineString geometry from the route coordinates
//         const routeLine = new LineString(routeCoords);

//         // Create a feature for the route
//         const routeFeature = new Feature({
//           geometry: routeLine,
//         });

//         // Apply a style to the route (green line for the shortest path)
//         routeFeature.setStyle(
//           new Style({
//             stroke: new Stroke({
//               color: '#00FF00', // Green color for shortest path
//               width: 3,
//             }),
//           })
//         );

//         // Add the route feature to the vector source
//         routeSource.clear(); // Clear any previous routes
//         routeSource.addFeature(routeFeature);

//         // Optionally, zoom to the route
//         const extent = routeLine.getExtent();
//         map.getView().fit(extent, { padding: [50, 50, 50, 50] });

//         // Log distance annotations for each segment
//         const distances = data.routes[0].legs[0].annotation.distance;
//         console.log("Segment distances in meters:", distances);
//       } else {
//         alert("No route found!");
//       }
//     })
//     .catch(error => {
//       console.error("Error fetching route data:", error);
//     });
// }

// // Add an event listener to the button to draw the shortest route
// document.getElementById('drawShortestRouteButton').addEventListener('click', () => {
//   drawShortestRoute();
// });
