import { gql, TypedDocumentNode, useLazyQuery } from "@apollo/client";
import {
  RidesNearbyQuery,
  RidesNearbyQueryVariables,
} from "@app/graphql/types";
import { LoaderIcon, SearchIcon } from "lucide-react";
import { Feature } from "ol";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls } from "ol/control";
import { Circle, Point } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";

const RIDES_NEARBY = gql`
  query RidesNearby($nearby: [Float!]!) {
    rides(nearby: $nearby) {
      edges {
        node {
          id
          title
          description
          startAt
          startLat
          startLng
          rideType
          distance
          calculatedDistance
        }
      }
    }
  }
` as TypedDocumentNode<RidesNearbyQuery, RidesNearbyQueryVariables>;

export const createMarkerUrl = (color = "#000000") => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" fill="white"></path>
      <circle cx="12" cy="10" r="3" fill="${color}"></circle>
    </svg>
  `
    .replace(/[\n\r]/g, "")
    .trim();

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
};

const RidersNearYou = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState<Map | null>(null);
  const [markerSource] = useState(new VectorSource());
  const [error, setError] = useState("");
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [userLocation, setUserLocation] = useState<number[] | null>(null);
  const [address, setAddress] = useState("");

  const [getRides, { loading, data }] = useLazyQuery(RIDES_NEARBY);

  const getAddressFromCoordinates = async (
    latitude: number,
    longitude: number,
  ) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`,
        {
          headers: {
            "Accept-Language": "en-US,en;q=0.9",
            "User-Agent": "BikeRide.Club/1.0",
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch address");
      }

      const data = await response.json();

      console.log(data);

      const addressParts = [];
      if (data.address) {
        const addr = data.address;
        if (addr.road) addressParts.push(addr.road);
        if (addr.suburb) addressParts.push(addr.suburb);
        if (addr.city || addr.town) addressParts.push(addr.city || addr.town);
        if (addr.state) addressParts.push(addr.state);
        if (addr.postcode) addressParts.push(addr.postcode);
        if (addr.country) addressParts.push(addr.country);
      }

      return addressParts.join(", ");
    } catch (error) {
      console.error("Error fetching address:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;

    const defaultView = new View({
      center: fromLonLat([0.1276, 51.5072]),
      zoom: 12,
    });

    const initialMap = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorLayer({
          source: markerSource,
        }),
      ],
      controls: defaultControls({ attribution: false }),
      target: mapRef.current,
      view: defaultView,
    });

    setMap(initialMap);

    setTimeout(() => {
      initialMap.updateSize();
    }, 100);

    return () => {
      if (initialMap) {
        initialMap.setTarget(undefined);
      }
    };
  }, [markerSource]);

  useEffect(() => {
    if (!userLocation) return;

    getAddressFromCoordinates(userLocation[0], userLocation[1])
      .then((address) => setAddress(address))
      .catch((error) => setError(error.message));

    getRides({ variables: { nearby: userLocation } });

    if (!map || !navigator.geolocation) {
      setError("Map not initialized or geolocation not supported");
      return;
    }

    const userCenter = fromLonLat([userLocation[1], userLocation[0]]);

    map.getView().animate({
      center: userCenter,
      zoom: 8,
      duration: 1000,
    });

    const marker = new Feature({ geometry: new Point(userCenter) });

    marker.setStyle(
      new Style({
        image: new Icon({
          src: createMarkerUrl(),
          scale: 1,
          anchor: [0.5, 1],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
        }),
      }),
    );

    const circleFeature = new Feature({
      geometry: new Circle(userCenter, 50000),
    });

    circleFeature.setStyle(
      new Style({
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 0.5)",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(0, 0, 255, 0.2)",
        }),
      }),
    );

    markerSource.clear();
    markerSource.addFeature(marker);
    markerSource.addFeature(circleFeature);

    setError("");
  }, [userLocation]);

  useEffect(() => {
    if (!data) return;

    data.rides.edges.forEach(({ node }) => {
      console.log(node);
      const rideLocation = fromLonLat([node.startLng, node.startLat]);

      const rideMarker = new Feature({
        geometry: new Point(rideLocation),
      });

      rideMarker.setStyle(
        new Style({
          image: new Icon({
            src: createMarkerUrl("#FF0000"), // Red color for ride markers
            scale: 1,
            anchor: [0.5, 1],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
          }),
        }),
      );

      markerSource.addFeature(rideMarker);
    });
  }, [data]);

  const centerMapOnUserLocation = () => {
    setLoadingLocation(true);
    setAddress("");

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLoadingLocation(false);
        const userLonLat = [
          position.coords.longitude,
          position.coords.latitude,
        ];
        setUserLocation([userLonLat[1], userLonLat[0]]);
      },
      (error) => {
        setLoadingLocation(false);
        setError(`Error getting location: ${error.message}`);
      },
    );
  };

  return (
    <div className="">
      <div ref={mapRef} className="w-full flex h-[300px]" />
      <Button
        onClick={centerMapOnUserLocation}
        className="mt-4"
        disabled={loadingLocation}
      >
        {loadingLocation && (
          <>
            <LoaderIcon className="animate-spin mr-2" size={16} />
            Searching for rides...
          </>
        )}
        {!loadingLocation && (
          <>
            <SearchIcon className="" size={16} />
            Locate rides near you
          </>
        )}
      </Button>
      {error && (
        <div className="absolute top-16 right-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-md z-10">
          {error}
        </div>
      )}
      {address && (
        <div className="mt-4 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
          <h3 className="font-medium mb-1">Your Location:</h3>
          <p className="text-gray-600">{address}</p>
        </div>
      )}

      {data &&
        (data.rides.edges.length > 0 ? (
          <div className="mt-4 bg-white border-gray-200 rounded-lg shadow-sm">
            <h3 className="font-medium mb-1">Rides near you:</h3>

            {data.rides.edges.map(({ node }) => (
              <Card key={node.id} className="mb-4">
                <CardHeader>
                  <CardTitle>{node.title}</CardTitle>
                  <CardDescription>{node.description}</CardDescription>
                </CardHeader>

                <CardContent>
                  <p>Ride Type: {node.rideType}</p>
                  <p>Distance: {node.distance} km</p>
                  <p>
                    Starts: {node.calculatedDistance.toFixed(2)} km from your
                    location
                  </p>
                  <Button className="mt-4">Join</Button>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <div className="mt-4 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <p>No riders found near you</p>
          </div>
        ))}
    </div>
  );
};

export default RidersNearYou;
