import { MapPinIcon } from "lucide-react";
import { Collection, Feature, View } from "ol";
import { defaults as defaultControls } from "ol/control";
import { Point } from "ol/geom";
import Translate from "ol/interaction/Translate";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import { fromLonLat, toLonLat } from "ol/proj";
import { OSM } from "ol/source";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import React, { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { createMarkerUrl } from "./riders_near_you";
import { Input } from "./ui/input";

interface Place {
  id: number;
  name: string;
  displayName: string;
  lat: number;
  lon: number;
}

const PlacesSearch: React.FC = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState<Map | null>(null);
  const [markerSource] = useState(new VectorSource());
  const [marker, setMarker] = useState<Feature<Point> | null>(null);

  const debouncedSearch = useDebouncedCallback(
    (value) => updateSuggestions(value),
    500,
  );
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [placesSuggestions, setPlacesSuggestions] = useState<Place[]>([]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const updateSuggestions = (value: string) => {
    if (value === "") {
      setPlacesSuggestions([]);
      return;
    }

    const nominatimURL = `https://nominatim.openstreetmap.org/search.php?q=${value}&polygon_geojson=1&format=jsonv2&limit=5`;

    fetch(nominatimURL)
      .then((response) => response.json())
      .then((data) => {
        setPlacesSuggestions(
          data.map((place) => {
            return {
              id: place.place_id,
              name: place.display_name,
              displayName: place.display_name,
              lat: place.lat,
              lon: place.lon,
            };
          }),
        );
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const centerMap = (lat: number, lon: number) => {
    if (!map) return;

    const view = map.getView();
    view.setCenter(fromLonLat([lon, lat]));
    view.setZoom(12);

    markerSource.clear();

    const center = fromLonLat([lon, lat]);
    const newMarker = new Feature({ geometry: new Point(center) });
    newMarker.setStyle(
      new Style({
        image: new Icon({
          src: createMarkerUrl(),
          scale: 1,
          anchor: [0.5, 1],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
        }),
      }),
    );
    markerSource.addFeature(newMarker);
    setMarker(newMarker);
  };

  useEffect(() => {
    if (!mapRef.current) return;

    const defaultView = new View({
      center: fromLonLat([0.1276, 51.5072]),
      zoom: 12,
    });

    const initialMap = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorLayer({
          source: markerSource,
        }),
      ],
      controls: defaultControls({ attribution: false }),
      target: mapRef.current,
      view: defaultView,
    });

    setMap(initialMap);
  }, [mapRef, markerSource]);

  useEffect(() => {
    if (marker && map) {
      const translate = new Translate({
        features: new Collection([marker]),
      });
      map.addInteraction(translate);

      translate.on("translateend", (event) => {
        const coords = toLonLat(
          (event.features.item(0).getGeometry() as Point).getCoordinates(),
        );
        console.log("Marker moved to: lat:", coords[1], "lon:", coords[0]);
      });
    }
  }, [marker]);

  return (
    <div>
      <div className="mb-4">
        <label>Search</label>
        <Input
          placeholder="Search..."
          value={searchInputValue}
          onChange={onInputChange}
          name="ride[ride_place]"
          type="text"
          data-1p-ignore
        />
        {placesSuggestions.length !== 0 && (
          <div className="relative mt-1">
            <div className="absolute z-50 block max-h-[430px] w-full max-w-screen-xl overflow-y-auto overflow-x-hidden rounded-md border bg-white">
              {placesSuggestions.map((place) => (
                <div className="flex w-full flex-col" key={place.id}>
                  <div
                    className="relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-secondary aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50"
                    onClick={() => {
                      setPlacesSuggestions([]);
                      setSearchInputValue(place.name);
                      centerMap(place.lat, place.lon);
                    }}
                  >
                    <MapPinIcon size={24} className="mr-2 min-w-4" />
                    <div className="flex flex-col">
                      <div className="font-semibold">{place.name}</div>
                      <div className="text-muted-foreground">
                        {place.displayName}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div ref={mapRef} className="w-full flex h-[300px]" />
    </div>
  );
};

export default PlacesSearch;
